import React, { useState } from 'react'

import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useValidationContent } from 'components/hooks/usePageContent/foms/usePageContent'
import Loading from 'components/Loading'
import TuixFieldForm from 'components/TuixFieldForm'
import { FormFieldData, FormValidationMessage } from 'components/TuixFieldForm/Form.interfaces'
import { FieldFormModel } from 'components/TuixFieldForm/TuixFieldForm'
import {
    MAX_DOCUMENT_FILE_SIZE,
    getDefaultEmptyValuesFromNode,
} from 'components/TuixFieldForm/TuixFieldForm.helpers'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Node, getDataByLocale } from 'utils/dataHelpers'
import { Locale } from 'utils/enums'
import tuixApiClient from 'utils/tuixClientApi'
import { ApplyFormData } from 'utils/types/emailForm.types'
import './ApplyForm.scss'

type Props = {
    applyImageURL: string
    errorText?: string
    successText?: string
    buttonText: string
    formFields: readonly Node<FieldFormModel>[]
    locale: Locale
}

type CustomEventHandler<DataType = unknown> = (e: CustomEvent<DataType>) => void

interface ApplyFormFieldData extends FormFieldData {
    first_name: string
    last_name: string
    email: string
    phone_number: string
    documents: File
    agree_terms: boolean
}

const getCustomRenderOptions = (lang: string): Options => {
    return {
        renderNode: {
            [INLINES.HYPERLINK]: ({ data }, children: JSX.Element) => {
                return (
                    <Link to={`/${lang}${data.uri}`} style={{ color: 'inherit' }}>
                        {children}
                    </Link>
                )
            },
        },
    }
}

export default ({
    applyImageURL,
    errorText,
    buttonText,
    formFields,
    locale,
    successText,
}: Props): JSX.Element => {
    const nodeValidationContent = useValidationContent()
    const fieldFormValidations = getDataByLocale<FormValidationMessage>(
        nodeValidationContent,
        locale,
    )
    const lang = locale.split('-')[0]
    const { isDarkMode } = useDarkModeContext()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const inputFields = [...formFields]
    const checkboxTerms = inputFields.pop()

    const labelCheckboxTerms = documentToReactComponents(
        JSON.parse(checkboxTerms.labelRichText.raw),
        getCustomRenderOptions(lang),
    )

    const { handleSubmit, control, reset, watch } = useForm({
        mode: 'all',
        defaultValues: getDefaultEmptyValuesFromNode(formFields),
    })

    const onSendForm = async (formData: ApplyFormFieldData) => {
        setIsLoading(true)
        setIsError(false)
        setIsSuccess(false)
        try {
            if (formData.documents?.size <= MAX_DOCUMENT_FILE_SIZE) {
                const dataToSend: ApplyFormData = {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    email: formData.email,
                    phone_number: formData.phone_number,
                    documents: formData.documents,
                    agree_terms: formData.agree_terms,
                }
                await tuixApiClient.sendEmail({
                    from: process.env.GATSBY_DEFAUL_EMAIL_FROM,
                    to: process.env.GATSBY_DEFAUL_EMAIL_FROM,
                    subject: 'CV Application',
                    templateUUID: process.env.GATSBY_APPLY_FORM_EMAIL_TEMPLATE_ID,
                    data: JSON.stringify(dataToSend),
                    file: dataToSend.documents,
                })
            }
            reset()
            setIsSuccess(true)
        } catch (e) {
            setIsError(true)
        }
        setIsLoading(false)
    }

    return (
        <div className="apply-form-container">
            <div className="apply-image-container">
                <img src={applyImageURL} alt="apply image" className="apply-image" />
            </div>
            <form className="apply-form">
                {inputFields.map((field, index) => {
                    return (
                        <TuixFieldForm
                            type={field.type}
                            validation={field.validation}
                            required={field.required}
                            id={field.id_field}
                            label={field.label}
                            key={index}
                            control={control}
                            fieldFormValidations={fieldFormValidations}
                        ></TuixFieldForm>
                    )
                })}
                <div className="checkbox-container">
                    <TuixFieldForm
                        label={checkboxTerms.label}
                        type={checkboxTerms.type}
                        id={checkboxTerms.id_field}
                        required={checkboxTerms.required}
                        control={control}
                        fieldFormValidations={fieldFormValidations}
                    >
                        <TuixTextComponent
                            size={TextSize.Field1}
                            color={TextVariant.GREY}
                            dark={isDarkMode}
                        >
                            {labelCheckboxTerms as JSX.Element}
                        </TuixTextComponent>
                    </TuixFieldForm>
                </div>
                <div className="button-container">
                    <TuixButtonComponent
                        onClick={handleSubmit(onSendForm) as unknown as CustomEventHandler}
                        disabled={isLoading || !watch('agree_terms')}
                    >
                        {buttonText}
                    </TuixButtonComponent>
                    {isLoading && <Loading />}
                    {isError && (
                        <TuixTextComponent
                            size={TextSize.Body1}
                            color={TextVariant.ERROR}
                            dark={isDarkMode}
                            className="error-text"
                        >
                            {errorText}
                        </TuixTextComponent>
                    )}
                    {isSuccess && (
                        <TuixTextComponent
                            size={TextSize.Body1}
                            color={TextVariant.SUCCESS}
                            dark={isDarkMode}
                            className="success-text"
                        >
                            {successText}
                        </TuixTextComponent>
                    )}
                </div>
            </form>
        </div>
    )
}
