import React from 'react'

import ApplyContent from 'components/ApplyContent'
import ApplyForm from 'components/ApplyContent/ApplyForm'
import Page from 'components/Page'
import Section from 'components/Section'
import { MetaData } from 'components/Seo/types'
import { FieldFormModel } from 'components/TuixFieldForm/TuixFieldForm'
import { graphql, PageProps } from 'gatsby'

import { getDataByLocale, mappingNode, Node } from '../../utils/dataHelpers'
import { Locale } from '../../utils/enums'
import { Asset } from '../../utils/types'
import './index.scss'

interface Props extends PageProps {
    pageContext: {
        slug: string
        locale: Locale
    }
    data: Record<string, { nodes: readonly Node<any>[] }>
}

interface ContentOfferModel {
    title: string
    text: { raw: string }
}

interface FormNodeModel {
    contentfulfields: readonly FieldFormModel[]
}

export default ({ pageContext: { locale }, data }: Props): JSX.Element => {
    const {
        contentOffer: { nodes: nodesContentOffer },
        textButton1: { nodes: nodesTextButton1 },
        applyImage: { nodes: nodesApplyImage },
        errorMessage: { nodes: nodesErrorText },
        successMessage: { nodes: nodesSuccessText },
        seoContent: { nodes: nodesSeoContent },
        formContent: { nodes: nodesFormContent },
    } = data

    const metaData = getDataByLocale<MetaData>(nodesSeoContent, locale)
    const { contentfulfields: formFieldsNodes } = getDataByLocale<FormNodeModel>(
        nodesFormContent,
        locale,
    )
    const formFields = mappingNode(formFieldsNodes, locale)
    const {
        file: { url: applyImageURL },
    } = getDataByLocale<Asset>(nodesApplyImage, locale)

    const { text: textButton1 } = getDataByLocale(nodesTextButton1, locale)
    const { text: errorText } = getDataByLocale(nodesErrorText, locale)
    const { text: successText } = getDataByLocale(nodesSuccessText, locale)
    const {
        title: titleOffer,
        text: { raw: offerText },
    } = getDataByLocale<ContentOfferModel>(nodesContentOffer, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section id="view-and-apply">
                <div className="view-and-apply-container">
                    <ApplyContent
                        offerTitle={titleOffer}
                        offerRawText={offerText}
                        applyImageURL={applyImageURL}
                    ></ApplyContent>
                    <ApplyForm
                        formFields={formFields}
                        applyImageURL={applyImageURL}
                        buttonText={textButton1}
                        errorText={errorText}
                        locale={locale}
                        successText={successText}
                    ></ApplyForm>
                </div>
            </Section>
        </Page>
    )
}

export const query = graphql`
    query QueryOffer($slug: String!, $locale: String!) {
        contentOffer: allContentfulApplyOffer(
            filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
        ) {
            nodes {
                node_locale
                title
                text {
                    raw
                }
                slug
            }
        }
        formContent: allContentfulFormContent(
            filter: { contentful_id: { eq: "1JVUS2rTamrWwMoAoVOVQH" } }
        ) {
            nodes {
                node_locale
                component
                contentfulfields {
                    label
                    labelRichText {
                        raw
                    }
                    validation
                    order
                    id_field
                    required
                    type
                    node_locale
                }
            }
        }
        textButton1: allContentfulTextButton(
            filter: { contentful_id: { eq: "2fMJDInYVAKfmg5nNHIbcC" } }
        ) {
            nodes {
                node_locale
                contentful_id
                text
            }
        }

        applyImage: allContentfulAsset(
            filter: { contentful_id: { eq: "1U3KCz0FaVAgsaekGlD25w" } }
        ) {
            nodes {
                node_locale
                file {
                    url
                }
            }
        }

        errorMessage: allContentfulError(
            filter: { contentful_id: { eq: "VjxjEyXwUcVrpQKr8UxOY" } }
        ) {
            nodes {
                node_locale
                text
            }
        }

        successMessage: allContentfulError(
            filter: { contentful_id: { eq: "3ARSfF1AtON3NOB10fHP4j" } }
        ) {
            nodes {
                node_locale
                text
            }
        }

        seoContent: allContentfulSeo(filter: { contentful_id: { eq: "7BdSKfND3gg5ZO0R5XXDrk" } }) {
            nodes {
                title
                url
                description {
                    description
                }
                image {
                    file {
                        url
                    }
                }
                node_locale
            }
        }
    }
`
